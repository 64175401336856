<script>
import { LOAD_CURRENT_STORE_INVENTORIES } from "@/store/actions.type";
import { mapGetters, mapState } from "vuex";
import JwtService from "@/common/jwt.service";
import PageHeader from "@/views/payouts/components/PageHeader.vue";

export default {
  name: 'Settings',

  components: {
    PageHeader
  },

  data() {
    return {
      loading: false
    }
  },

  created: async function() {
    if(!this.currentShop) {
      this.loading = true
      await this.$store.dispatch(`shop/init`).then(() => {
        if (this.currentShop != null) {
          this.$store.dispatch(`shop/${LOAD_CURRENT_STORE_INVENTORIES}`);
        }
      });
      this.loading = false
    }
  },

  computed: {
    ...mapGetters("plan", ["isProductModuleAvailable", "isPayoutsModuleAvailable"]),
    ...mapState("shop", ["currentShop"]),
    wooPlanSelectionLink() {
      return `${ process.env.VUE_APP_WOO_BILLING}/?jwt=${JwtService.getToken() }`
    },
  },

  methods: {
    goToPage(page) {
      this.$router.push({ name: page })
    },

    goToWooPlanSelection() {
      window.location.href = this.wooPlanSelectionLink
    }
  }
}
</script>

<template>
  <section class="v2 settings">
    <!-- Page Header -->
    <PageHeader pageDescription="Manage your settings to make Syncio work better for you">
      <template #title>Settings</template>
    </PageHeader>

    <v-row v-if="!loading" class="m-t-5">
      <!-- Account Details -->
      <v-col cols="4">
        <v-card elevation="1" class="px-6 py-4 fill-height pointer card-rounded" @click="goToPage('AccountSettingsPage')">
          <template slot>
            <h4 class="d-flex align-center">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2" style="transform: translateY(-.5px)">
                <path d="M12.5 12.75C11.7583 12.75 11.0333 12.5301 10.4166 12.118C9.79993 11.706 9.31928 11.1203 9.03545 10.4351C8.75163 9.74984 8.67736 8.99584 8.82206 8.26841C8.96675 7.54098 9.3239 6.8728 9.84835 6.34835C10.3728 5.8239 11.041 5.46675 11.7684 5.32206C12.4958 5.17736 13.2498 5.25162 13.9351 5.53545C14.6203 5.81928 15.206 6.29993 15.618 6.91661C16.0301 7.5333 16.25 8.25832 16.25 9C16.25 9.99456 15.8549 10.9484 15.1517 11.6517C14.4484 12.3549 13.4946 12.75 12.5 12.75ZM12.5 6.75C12.055 6.75 11.62 6.88196 11.25 7.12919C10.88 7.37643 10.5916 7.72783 10.4213 8.13896C10.251 8.5501 10.2064 9.0025 10.2932 9.43895C10.3801 9.87541 10.5943 10.2763 10.909 10.591C11.2237 10.9057 11.6246 11.12 12.061 11.2068C12.4975 11.2936 12.9499 11.249 13.361 11.0787C13.7722 10.9084 14.1236 10.62 14.3708 10.25C14.618 9.88002 14.75 9.44501 14.75 9C14.75 8.40326 14.5129 7.83097 14.091 7.40901C13.669 6.98705 13.0967 6.75 12.5 6.75Z" fill="#212121"/>
                <path d="M19.5 19.75C19.3019 19.7474 19.1126 19.6676 18.9725 19.5275C18.8324 19.3874 18.7526 19.1981 18.75 19C18.75 17.05 17.69 15.75 12.5 15.75C7.31 15.75 6.25 17.05 6.25 19C6.25 19.1989 6.17098 19.3897 6.03033 19.5303C5.88968 19.671 5.69891 19.75 5.5 19.75C5.30109 19.75 5.11032 19.671 4.96967 19.5303C4.82902 19.3897 4.75 19.1989 4.75 19C4.75 14.25 10.18 14.25 12.5 14.25C14.82 14.25 20.25 14.25 20.25 19C20.2474 19.1981 20.1676 19.3874 20.0275 19.5275C19.8874 19.6676 19.6981 19.7474 19.5 19.75Z" fill="#212121"/>
              </svg>
              <span>
                Account details
              </span>
            </h4>
            <p>Find specific information about your account with Syncio, including your store type, Syncio connection key and how to uninstall Syncio</p>
          </template>
        </v-card>
      </v-col>

      <!-- Plan -->
      <v-col cols="4" v-if="currentShop && currentShop.type === 'destination'">
        <v-card v-if="currentShop && currentShop.platform === 'shopify'" elevation="1" class="px-6 py-4 fill-height pointer card-rounded" @click="goToPage('PlanSelectionPage')">
          <template slot>
            <h4 class="d-flex align-center">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2" style="transform: translateY(-.5px)">
                <path d="M19.03 9.5L13.5 3.97C13.3595 3.82931 13.1688 3.75018 12.97 3.75H8.5C7.77065 3.75 7.07118 4.03973 6.55546 4.55546C6.03973 5.07118 5.75 5.77065 5.75 6.5V18.5C5.75 19.2293 6.03973 19.9288 6.55546 20.4445C7.07118 20.9603 7.77065 21.25 8.5 21.25H16.5C17.2293 21.25 17.9288 20.9603 18.4445 20.4445C18.9603 19.9288 19.25 19.2293 19.25 18.5V10C19.2421 9.8116 19.1636 9.63309 19.03 9.5ZM13.75 6.31L16.69 9.25H13.75V6.31ZM16.5 19.75H8.5C8.16848 19.75 7.85054 19.6183 7.61612 19.3839C7.3817 19.1495 7.25 18.8315 7.25 18.5V6.5C7.25 6.16848 7.3817 5.85054 7.61612 5.61612C7.85054 5.3817 8.16848 5.25 8.5 5.25H12.25V10C12.2526 10.1981 12.3324 10.3874 12.4725 10.5275C12.6126 10.6676 12.8019 10.7474 13 10.75H17.75V18.5C17.75 18.8315 17.6183 19.1495 17.3839 19.3839C17.1495 19.6183 16.8315 19.75 16.5 19.75Z" fill="#212121"/>
              </svg>
              Plan and billing
            </h4>
            <p>Manage and upgrade your base plan and add-ons</p>
          </template>
        </v-card>

        <v-card v-if="currentShop && currentShop.platform === 'woocommerce'" elevation="1" class="px-6 py-4 fill-height pointer card-rounded" @click="goToWooPlanSelection">
          <template slot>
            <h4 class="d-flex align-center">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2" style="transform: translateY(-.5px)">
                <path d="M19.03 9.5L13.5 3.97C13.3595 3.82931 13.1688 3.75018 12.97 3.75H8.5C7.77065 3.75 7.07118 4.03973 6.55546 4.55546C6.03973 5.07118 5.75 5.77065 5.75 6.5V18.5C5.75 19.2293 6.03973 19.9288 6.55546 20.4445C7.07118 20.9603 7.77065 21.25 8.5 21.25H16.5C17.2293 21.25 17.9288 20.9603 18.4445 20.4445C18.9603 19.9288 19.25 19.2293 19.25 18.5V10C19.2421 9.8116 19.1636 9.63309 19.03 9.5ZM13.75 6.31L16.69 9.25H13.75V6.31ZM16.5 19.75H8.5C8.16848 19.75 7.85054 19.6183 7.61612 19.3839C7.3817 19.1495 7.25 18.8315 7.25 18.5V6.5C7.25 6.16848 7.3817 5.85054 7.61612 5.61612C7.85054 5.3817 8.16848 5.25 8.5 5.25H12.25V10C12.2526 10.1981 12.3324 10.3874 12.4725 10.5275C12.6126 10.6676 12.8019 10.7474 13 10.75H17.75V18.5C17.75 18.8315 17.6183 19.1495 17.3839 19.3839C17.1495 19.6183 16.8315 19.75 16.5 19.75Z" fill="#212121"/>
              </svg>
              Plan and billing
            </h4>
            <p>Manage and upgrade your base plan and add-ons</p>
          </template>
        </v-card>
      </v-col>

      <!-- Products -->
      <v-col cols="4" v-if="currentShop && currentShop.platform === 'shopify'">
        <v-card v-if="isProductModuleAvailable && (currentShop && currentShop.type === 'destination')" elevation="1" class="px-6 py-4 fill-height pointer card-rounded" @click="goToPage('ProductConfigurationsPage')">
          <template slot>
            <h4 class="d-flex align-center">
              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2" style="transform: translateY(-.5px)">
                <g clip-path="url(#clip0_130_26721)">
                  <path d="M3.83301 8.75C3.14134 8.75 2.58301 9.30833 2.58301 10C2.58301 10.6917 3.14134 11.25 3.83301 11.25C4.52467 11.25 5.08301 10.6917 5.08301 10C5.08301 9.30833 4.52467 8.75 3.83301 8.75ZM3.83301 3.75C3.14134 3.75 2.58301 4.30833 2.58301 5C2.58301 5.69167 3.14134 6.25 3.83301 6.25C4.52467 6.25 5.08301 5.69167 5.08301 5C5.08301 4.30833 4.52467 3.75 3.83301 3.75ZM3.83301 13.75C3.14134 13.75 2.58301 14.3167 2.58301 15C2.58301 15.6833 3.14967 16.25 3.83301 16.25C4.51634 16.25 5.08301 15.6833 5.08301 15C5.08301 14.3167 4.52467 13.75 3.83301 13.75ZM6.33301 15.8333H17.9997V14.1667H6.33301V15.8333ZM6.33301 10.8333H17.9997V9.16667H6.33301V10.8333ZM6.33301 4.16667V5.83333H17.9997V4.16667H6.33301Z" fill="#495057"/>
                </g>
                <defs>
                  <clipPath id="clip0_130_26721">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
              </svg>
              Product settings
            </h4>
            <p>Manage what attributes sync across your connected stores</p>
          </template>
        </v-card>

        <v-card v-else-if="!isProductModuleAvailable && (currentShop && currentShop.type === 'destination')" elevation="1" class="px-6 py-4 fill-height pointer card-rounded" @click="goToPage('PlanSelectionPage')">
          <template slot>
            <h4 class="d-flex align-center">
              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2" style="transform: translateY(-.5px)">
                <g clip-path="url(#clip0_130_26721)">
                  <path d="M3.83301 8.75C3.14134 8.75 2.58301 9.30833 2.58301 10C2.58301 10.6917 3.14134 11.25 3.83301 11.25C4.52467 11.25 5.08301 10.6917 5.08301 10C5.08301 9.30833 4.52467 8.75 3.83301 8.75ZM3.83301 3.75C3.14134 3.75 2.58301 4.30833 2.58301 5C2.58301 5.69167 3.14134 6.25 3.83301 6.25C4.52467 6.25 5.08301 5.69167 5.08301 5C5.08301 4.30833 4.52467 3.75 3.83301 3.75ZM3.83301 13.75C3.14134 13.75 2.58301 14.3167 2.58301 15C2.58301 15.6833 3.14967 16.25 3.83301 16.25C4.51634 16.25 5.08301 15.6833 5.08301 15C5.08301 14.3167 4.52467 13.75 3.83301 13.75ZM6.33301 15.8333H17.9997V14.1667H6.33301V15.8333ZM6.33301 10.8333H17.9997V9.16667H6.33301V10.8333ZM6.33301 4.16667V5.83333H17.9997V4.16667H6.33301Z" fill="#495057"/>
                </g>
                <defs>
                  <clipPath id="clip0_130_26721">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
              </svg>
              Product settings
            </h4>
            <p>Manage what attributes sync across your connected stores</p>

            <router-link to="account/billing" v-if="!isProductModuleAvailable" class="link link-underline m-t-3 d-inline-flex">Unlock this add-on</router-link>
          </template>
        </v-card>

        <v-card v-else-if="currentShop && currentShop.type === 'source'" elevation="1" class="px-6 py-4 fill-height pointer card-rounded" @click="goToPage('ProductConfigurationsPage')">
          <template slot>
            <h4 class="d-flex align-center">
              <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2" style="transform: translateY(-.5px)">
                <g clip-path="url(#clip0_130_26721)">
                  <path d="M3.83301 8.75C3.14134 8.75 2.58301 9.30833 2.58301 10C2.58301 10.6917 3.14134 11.25 3.83301 11.25C4.52467 11.25 5.08301 10.6917 5.08301 10C5.08301 9.30833 4.52467 8.75 3.83301 8.75ZM3.83301 3.75C3.14134 3.75 2.58301 4.30833 2.58301 5C2.58301 5.69167 3.14134 6.25 3.83301 6.25C4.52467 6.25 5.08301 5.69167 5.08301 5C5.08301 4.30833 4.52467 3.75 3.83301 3.75ZM3.83301 13.75C3.14134 13.75 2.58301 14.3167 2.58301 15C2.58301 15.6833 3.14967 16.25 3.83301 16.25C4.51634 16.25 5.08301 15.6833 5.08301 15C5.08301 14.3167 4.52467 13.75 3.83301 13.75ZM6.33301 15.8333H17.9997V14.1667H6.33301V15.8333ZM6.33301 10.8333H17.9997V9.16667H6.33301V10.8333ZM6.33301 4.16667V5.83333H17.9997V4.16667H6.33301Z" fill="#495057"/>
                </g>
                <defs>
                  <clipPath id="clip0_130_26721">
                    <rect width="20" height="20" fill="white" transform="translate(0.5)"/>
                  </clipPath>
                </defs>
              </svg>
              Product settings
            </h4>
            <p>Manage what attributes sync across your connected stores</p>
          </template>
        </v-card>
      </v-col>

      <!-- Payouts -->
      <v-col cols="4" v-if="currentShop && currentShop.platform === 'shopify' && currentShop.type === 'destination'">
        <v-card v-if="isPayoutsModuleAvailable" elevation="1" class="px-6 py-4 fill-height pointer card-rounded" @click="goToPage('PayoutSettings')">
          <template slot>
            <h4 class="d-flex align-center">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2" style="transform: translateY(-.5px)">
                <path d="M12.5 21.25C12.3019 21.2474 12.1126 21.1676 11.9725 21.0275C11.8324 20.8874 11.7526 20.6981 11.75 20.5V4.5C11.75 4.30109 11.829 4.11032 11.9697 3.96967C12.1103 3.82902 12.3011 3.75 12.5 3.75C12.6989 3.75 12.8897 3.82902 13.0303 3.96967C13.171 4.11032 13.25 4.30109 13.25 4.5V20.5C13.2474 20.6981 13.1676 20.8874 13.0275 21.0275C12.8874 21.1676 12.6981 21.2474 12.5 21.25Z" fill="#212121"/>
                <path d="M14 19.25H7.5C7.30109 19.25 7.11032 19.171 6.96967 19.0303C6.82902 18.8897 6.75 18.6989 6.75 18.5C6.75 18.3011 6.82902 18.1103 6.96967 17.9697C7.11032 17.829 7.30109 17.75 7.5 17.75H14C14.6615 17.8089 15.3199 17.6064 15.8339 17.1859C16.3479 16.7653 16.6768 16.1601 16.75 15.5C16.6768 14.8399 16.3479 14.2347 15.8339 13.8141C15.3199 13.3935 14.6615 13.191 14 13.25H11C10.4745 13.2839 9.94746 13.2136 9.44915 13.0433C8.95085 12.873 8.49107 12.606 8.0962 12.2576C7.70134 11.9092 7.37915 11.4863 7.14814 11.013C6.91712 10.5398 6.78182 10.0256 6.75 9.49998C6.78182 8.97434 6.91712 8.46016 7.14814 7.98694C7.37915 7.51371 7.70134 7.09076 8.0962 6.74235C8.49107 6.39394 8.95085 6.12692 9.44915 5.95663C9.94746 5.78633 10.4745 5.71611 11 5.74998H16.5C16.6989 5.74998 16.8897 5.829 17.0303 5.96965C17.171 6.11031 17.25 6.30107 17.25 6.49998C17.25 6.6989 17.171 6.88966 17.0303 7.03031C16.8897 7.17097 16.6989 7.24998 16.5 7.24998H11C10.3385 7.19103 9.68013 7.39353 9.1661 7.8141C8.65206 8.23468 8.32321 8.83987 8.25 9.49998C8.32321 10.1601 8.65206 10.7653 9.1661 11.1859C9.68013 11.6064 10.3385 11.8089 11 11.75H14C14.5255 11.7161 15.0525 11.7863 15.5508 11.9566C16.0492 12.1269 16.5089 12.3939 16.9038 12.7423C17.2987 13.0908 17.6208 13.5137 17.8519 13.9869C18.0829 14.4602 18.2182 14.9743 18.25 15.5C18.2182 16.0256 18.0829 16.5398 17.8519 17.013C17.6208 17.4863 17.2987 17.9092 16.9038 18.2576C16.5089 18.606 16.0492 18.873 15.5508 19.0433C15.0525 19.2136 14.5255 19.2839 14 19.25Z" fill="#212121"/>
              </svg>
              Payouts settings
            </h4>
            <p>Set commissions at store, vendor or product level</p>
          </template>
        </v-card>
        <v-card v-else elevation="1" class="px-6 py-4 fill-height pointer card-rounded" @click="goToPage('PlanSelectionPage')">
          <template slot>
            <h4 class="d-flex align-center">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2" style="transform: translateY(-.5px)">
                <path d="M12.5 21.25C12.3019 21.2474 12.1126 21.1676 11.9725 21.0275C11.8324 20.8874 11.7526 20.6981 11.75 20.5V4.5C11.75 4.30109 11.829 4.11032 11.9697 3.96967C12.1103 3.82902 12.3011 3.75 12.5 3.75C12.6989 3.75 12.8897 3.82902 13.0303 3.96967C13.171 4.11032 13.25 4.30109 13.25 4.5V20.5C13.2474 20.6981 13.1676 20.8874 13.0275 21.0275C12.8874 21.1676 12.6981 21.2474 12.5 21.25Z" fill="#212121"/>
                <path d="M14 19.25H7.5C7.30109 19.25 7.11032 19.171 6.96967 19.0303C6.82902 18.8897 6.75 18.6989 6.75 18.5C6.75 18.3011 6.82902 18.1103 6.96967 17.9697C7.11032 17.829 7.30109 17.75 7.5 17.75H14C14.6615 17.8089 15.3199 17.6064 15.8339 17.1859C16.3479 16.7653 16.6768 16.1601 16.75 15.5C16.6768 14.8399 16.3479 14.2347 15.8339 13.8141C15.3199 13.3935 14.6615 13.191 14 13.25H11C10.4745 13.2839 9.94746 13.2136 9.44915 13.0433C8.95085 12.873 8.49107 12.606 8.0962 12.2576C7.70134 11.9092 7.37915 11.4863 7.14814 11.013C6.91712 10.5398 6.78182 10.0256 6.75 9.49998C6.78182 8.97434 6.91712 8.46016 7.14814 7.98694C7.37915 7.51371 7.70134 7.09076 8.0962 6.74235C8.49107 6.39394 8.95085 6.12692 9.44915 5.95663C9.94746 5.78633 10.4745 5.71611 11 5.74998H16.5C16.6989 5.74998 16.8897 5.829 17.0303 5.96965C17.171 6.11031 17.25 6.30107 17.25 6.49998C17.25 6.6989 17.171 6.88966 17.0303 7.03031C16.8897 7.17097 16.6989 7.24998 16.5 7.24998H11C10.3385 7.19103 9.68013 7.39353 9.1661 7.8141C8.65206 8.23468 8.32321 8.83987 8.25 9.49998C8.32321 10.1601 8.65206 10.7653 9.1661 11.1859C9.68013 11.6064 10.3385 11.8089 11 11.75H14C14.5255 11.7161 15.0525 11.7863 15.5508 11.9566C16.0492 12.1269 16.5089 12.3939 16.9038 12.7423C17.2987 13.0908 17.6208 13.5137 17.8519 13.9869C18.0829 14.4602 18.2182 14.9743 18.25 15.5C18.2182 16.0256 18.0829 16.5398 17.8519 17.013C17.6208 17.4863 17.2987 17.9092 16.9038 18.2576C16.5089 18.606 16.0492 18.873 15.5508 19.0433C15.0525 19.2136 14.5255 19.2839 14 19.25Z" fill="#212121"/>
              </svg>
              Payouts settings
            </h4>
            <p>Set commissions at store, vendor or product level</p>

            <router-link to="account/billing" class="link link-underline m-t-3 d-inline-flex">Unlock this add-on</router-link>
          </template>
        </v-card>
      </v-col>

      <!-- Marketplace -->
      <v-col cols="4">
        <v-card elevation="1" class="px-6 py-4 fill-height pointer card-rounded" @click="goToPage('MarketplaceSettings')">
          <template slot>
            <h4 class="d-flex align-center">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2">
                <path d="M20 8.75H17V8.25C17 7.05653 16.5259 5.91193 15.682 5.06802C14.8381 4.22411 13.6935 3.75 12.5 3.75C11.3065 3.75 10.1619 4.22411 9.31802 5.06802C8.47411 5.91193 8 7.05653 8 8.25V8.75H5C4.66848 8.75 4.35054 8.8817 4.11612 9.11612C3.8817 9.35054 3.75 9.66848 3.75 10V18.5C3.75 19.2293 4.03973 19.9288 4.55546 20.4445C5.07118 20.9603 5.77065 21.25 6.5 21.25H18.5C19.2293 21.25 19.9288 20.9603 20.4445 20.4445C20.9603 19.9288 21.25 19.2293 21.25 18.5V10C21.25 9.66848 21.1183 9.35054 20.8839 9.11612C20.6495 8.8817 20.3315 8.75 20 8.75ZM9.5 8.25C9.5 7.45435 9.81607 6.69129 10.3787 6.12868C10.9413 5.56607 11.7044 5.25 12.5 5.25C13.2956 5.25 14.0587 5.56607 14.6213 6.12868C15.1839 6.69129 15.5 7.45435 15.5 8.25V8.75H9.5V8.25ZM19.75 18.5C19.75 18.8315 19.6183 19.1495 19.3839 19.3839C19.1495 19.6183 18.8315 19.75 18.5 19.75H6.5C6.16848 19.75 5.85054 19.6183 5.61612 19.3839C5.3817 19.1495 5.25 18.8315 5.25 18.5V10.25H8V12.5C8 12.6989 8.07902 12.8897 8.21967 13.0303C8.36032 13.171 8.55109 13.25 8.75 13.25C8.94891 13.25 9.13968 13.171 9.28033 13.0303C9.42098 12.8897 9.5 12.6989 9.5 12.5V10.25H15.5V12.5C15.5 12.6989 15.579 12.8897 15.7197 13.0303C15.8603 13.171 16.0511 13.25 16.25 13.25C16.4489 13.25 16.6397 13.171 16.7803 13.0303C16.921 12.8897 17 12.6989 17 12.5V10.25H19.75V18.5Z" fill="#212121"/>
              </svg>
              Marketplace settings
            </h4>
            <p>Manage your Marketplace profile and settings</p>
          </template>
        </v-card>
      </v-col>

      <!-- Notification -->
      <v-col cols="4">
        <v-card elevation="1" class="px-6 py-4 fill-height pointer card-rounded" @click="goToPage('NotificationSettings')">
          <template slot>
            <h4 class="d-flex align-center">
              <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg" class="mr-2" style="transform: translateY(-.5px)">
                <path d="M21.03 16.75C20.94 16.75 18.92 16.39 18.92 10.5C18.92 6.34 16.5 3.75 12.5 3.75C8.5 3.75 6.08 6.34 6.08 10.5C6.08 16.5 3.99 16.75 4 16.75C3.80109 16.75 3.61032 16.829 3.46967 16.9697C3.32902 17.1103 3.25 17.3011 3.25 17.5C3.25 17.6989 3.32902 17.8897 3.46967 18.0303C3.61032 18.171 3.80109 18.25 4 18.25H8.83C8.99694 19.1007 9.45423 19.867 10.1237 20.4178C10.7931 20.9686 11.6331 21.2698 12.5 21.2698C13.3669 21.2698 14.2069 20.9686 14.8763 20.4178C15.5458 19.867 16.0031 19.1007 16.17 18.25H21.01C21.2089 18.25 21.3997 18.171 21.5403 18.0303C21.681 17.8897 21.76 17.6989 21.76 17.5C21.76 17.3011 21.681 17.1103 21.5403 16.9697C21.3997 16.829 21.2089 16.75 21.01 16.75H21.03ZM12.5 19.75C12.0363 19.7491 11.5843 19.6044 11.2064 19.3357C10.8284 19.067 10.5432 18.6877 10.39 18.25H14.61C14.4568 18.6877 14.1716 19.067 13.7936 19.3357C13.4157 19.6044 12.9637 19.7491 12.5 19.75ZM6.26 16.75C6.98 15.66 7.58 13.75 7.58 10.5C7.58 7.25 9.38 5.25 12.5 5.25C15.62 5.25 17.42 7.16 17.42 10.5C17.42 13.84 18.02 15.66 18.74 16.75H6.26Z" fill="#212121"/>
              </svg>
              Notifications
            </h4>
            <p>Choose how you'd like to receive communications for your account</p>
          </template>
        </v-card>
      </v-col>
    </v-row>

  </section>
</template>

<style lang="scss">
.settings {
  margin: { left: auto; right: auto };
  width: 1024px;

  h4 {
    color: #0E3B4D;
    font-size: 21px;
    line-height: 25px;
    margin-bottom: 16px !important;
  }

  p {
    color: #0E3B4D;
    font-size: 14px;
    font-weight: 300;
    line-height: 22px;
    margin-bottom: 0;
  }

  .fill-height {
    min-height: 186px;
  }

  .card-rounded {
    border-radius: 12px !important;

    &:hover {
      .link-underline {
        &:before {
          width: 100%;
        }
      }
    }
  }
}
</style>
